import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"id":""}},[_c(VCardText,{staticClass:"d-flex"},[_c(VRow,{staticClass:"mt-1"},[_c(VAutocomplete,{staticClass:"mr-2",attrs:{"items":_vm.listRegionsItems,"label":"Regional","outlined":"","dense":"","md":"2"},model:{value:(_vm.itemSearch),callback:function ($$v) {_vm.itemSearch=$$v},expression:"itemSearch"}}),_c(VAutocomplete,{staticClass:"mr-2",attrs:{"items":_vm.companyList,"item-text":"fantasy_name","item-value":"fantasy_name","label":"Filial","outlined":"","dense":"","md":"2"},model:{value:(_vm.companyNameSelected),callback:function ($$v) {_vm.companyNameSelected=$$v},expression:"companyNameSelected"}}),_c(VAutocomplete,{staticClass:"mr-2",attrs:{"items":_vm.listStatus,"label":"Status","outlined":"","dense":""},model:{value:(_vm.itemSearchStatus),callback:function ($$v) {_vm.itemSearchStatus=$$v},expression:"itemSearchStatus"}}),_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Mês referência","hide-details":"","outlined":"","readonly":"","dense":""},model:{value:(_vm.computedItemSearchDate),callback:function ($$v) {_vm.computedItemSearchDate=$$v},expression:"computedItemSearchDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c(VDatePicker,{attrs:{"locale":"pt-BR","type":"month","width":"340"},on:{"dblclick:month":function($event){return _vm.handleMonthSelection()}},model:{value:(_vm.itemSearchDate),callback:function ($$v) {_vm.itemSearchDate=$$v},expression:"itemSearchDate"}})],1),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"info","variant":"tonal","disabled":_vm.isLoadingValidate},on:{"click":_vm.getDataSalesInputSelected}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSendCircleOutline)+" ")])],1),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"error","variant":"tonal","disabled":_vm.isLoadingValidate},on:{"click":_vm.clearFilter}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCircleOffOutline)+" ")])],1),_c(VSpacer),(_vm.isLoadingExport)?_c(VBtn,{staticClass:"ml-2",attrs:{"color":"warning","outlined":""}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"gray","size":25}})],1):_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"warning","outlined":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])],1)]}}])},[_c(VList,_vm._l((_vm.itemsListExport),function(item){return _c(VListItem,{key:item,attrs:{"link":""}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item)},on:{"click":function($event){return _vm.exportCsv(item)}}})],1)}),1)],1)],1)],1),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.isLoadingTableData,"loading-text":"Carregando dados...","disable-sort":""},scopedSlots:_vm._u([{key:"item.vacation",fn:function(ref){
var item = ref.item;
return [_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(item.vacation === true),expression:"item.vacation === true"}],staticClass:"info--text",attrs:{"color":"info","prepend-icon":"mdi-account-circle"}},[_vm._v(" "+_vm._s(item.vacation ? 'Férias' : '')+" ")])]}},{key:"item.controlling_status",fn:function(ref){
var item = ref.item;
return [_c(VAlert,{staticClass:"badge",attrs:{"text":"","color":item.controlling_status === 'VALIDADO'
            ? 'success'
            : item.controlling_status === 'REGISTRADO'
              ? 'primary'
              : 'error'}},[_vm._v(" "+_vm._s(item.controlling_status)+" ")])]}},{key:"item.self",fn:function(ref){
              var item = ref.item;
return [_vm._v(" #"+_vm._s(item.self)+" ")]}},{key:"item.valor_vehicles",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.valor_vehicles ? Number(item.valor_vehicles).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00')+" ")]}},{key:"item.commission",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.commission ? Number(item.commission).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00')+" ")]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:("v-avatar-light-bg " + (_vm.resolveUserProgressVariant(item.controlling_status)) + "--text"),attrs:{"color":_vm.resolveUserProgressVariant(item.controlling_status),"size":"40"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.name)))])]),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.name))])])],1)]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [(item.reference === _vm.loadingCircle ? false : true)?_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":function($event){return _vm.detailsItems(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")]):_vm._e(),(item.reference === _vm.loadingCircle ? true : false)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":25,"color":"gray"}}):_vm._e()]}},{key:"no-data",fn:function(){return [_c('span',{class:_vm.classNoDataText},[_vm._v(_vm._s(_vm.noDataText))])]},proxy:true}]),model:{value:(_vm.itemTableSelected),callback:function ($$v) {_vm.itemTableSelected=$$v},expression:"itemTableSelected"}}),_c(VDialog,{attrs:{"max-width":"1600px"},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('Details',{key:_vm.vendor_name,attrs:{"reference":_vm.reference,"vendor-name":_vm.vendor_name,"status-sales":_vm.statusSales,"vendor-cpf":_vm.vendor_cpf},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.openModal = false}}})],1),_c(VDialog,{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.isLoadingValidate),callback:function ($$v) {_vm.isLoadingValidate=$$v},expression:"isLoadingValidate"}},[_c(VCard,{attrs:{"color":"purple","dark":""}},[_c(VCardText,[_vm._v(" Aguarde, estamos validando "),_c(VProgressLinear,{staticClass:"mb-0 mt-2",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }