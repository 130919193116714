<template>
  <v-card>
    <v-snackbar
      v-model="snackbarInconsistency"
      location="top"
      :color="statusSales === 'INCONSISTENTE' ? 'error' : 'success'"
    >
      {{
        statusSales === 'INCONSISTENTE'
          ? 'Este item já possui um RI aberto. Direcione-se ao menu de RI´s'
          : 'Impossível, este item já foi verificado'
      }}
    </v-snackbar>

    <v-card-title>
      Relatório detalhado - {{ vendorName }} - {{ statusSales }}

      <v-spacer />

      <div v-show="verifyUser === 'null'">
        <v-btn
          v-if="statusSales === 'REGISTRADO'"
          class="mr-5"
          color="error"
          @click="openInconsistenciesModal"
        >
          <span>inconsistência</span>
          <v-icon
            size="18"
            class="ml-1"
          >
            {{ icons.mdiAlertDecagram }}
          </v-icon>
        </v-btn>

        <v-btn
          v-else
          class="mr-5"
          color="error"
          @click="snackbarInconsistency = true"
        >
          <v-icon
            size="18"
            class="ml-1"
          >
            {{ icons.mdiAlertDecagram }}
          </v-icon>
        </v-btn>
      </div>

      <div v-show="verifyUser === 'null'">
        <v-btn
          v-if="(statusSales === 'REGISTRADO') | (statusSales === 'INCONSISTENTE')"
          class="mr-5"
          color="info"
          @click="validateSales"
        >
          <span>Validar</span>
          <v-icon
            size="18"
            class="ml-1"
          >
            {{ icons.mdiCheckDecagram }}
          </v-icon>
        </v-btn>

        <v-btn
          v-else
          class="mr-5"
          color="success"
          @click="snackbarInconsistency = true"
        >
          <v-icon
            size="18"
            class="ml-1"
          >
            {{ icons.mdiCheckDecagram }}
          </v-icon>
        </v-btn>
      </div>

      <v-btn
        class="mr-4"
        color="warning"
        icon
        @click="exportCsv()"
      >
        <v-icon size="30">
          {{ icons.mdiDownload }}
        </v-icon>
      </v-btn>
      <v-icon @click="$emit('close')">
        {{ icons.mdiClose }}
      </v-icon>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="reports"
        disable-sort
      >
        <template #[`item.percentage`]="{ item }">
          {{ Number(item.percentage) }}
        </template>
        <template #[`item.sales`]="{ item }">
          {{
            item.sales ? Number(item.sales).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'
          }}
        </template>
        <template #[`item.proposal_number`]="{ item }">
          {{ item.proposal_number }} {{ item.proposal_digit }}
        </template>
        <template #[`item.contract_status`]="{ item }">
          <v-alert
            text
            class="badge"
            :color="
              item.contract_status.trim() === 'PRE-CONTEMPLADO'
                ? 'info'
                : item.contract_status.trim() === 'PRE-CANCELADO'
                  ? 'purple'
                  : item.contract_status.trim() === 'CANCELADO'
                    ? 'error'
                    : item.contract_status.trim() === 'CANCELADO NO GRUPO'
                      ? 'error'
                      : item.contract_status.trim() === 'NORMAL'
                        ? 'success'
                        : item.contract_status.trim() === 'CONTEMPLADO'
                          ? 'success'
                          : ''
            "
          >
            {{ item.contract_status }}
          </v-alert>
        </template>
        <template #[`item.commission`]="{ item }">
          {{
            item.commission
              ? Number(item.commission).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
              : 'R$ 0,00'
          }}
        </template>

        <template #[`item.delete`]="{ item }">
          <v-icon
            v-show="computedUserIsLeader"
            medium
            class="me-2"
            color="error"
            @click="showModalDelete(item.id)"
          >
            {{ icons.mdiDelete }}
          </v-icon>
        </template>

        <!--  eslint-disable-next-line vue/no-unused-vars -->
        <template v-slot:foot="{ items }">
          <tfoot class="footer">
            <th class="contracts">
              <h3>
                {{ footer.contracts }}
              </h3>
            </th>
            <th>-</th>
            <th>-</th>
            <th>-</th>
            <th class="">
              <h4>
                {{ totalCredit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
              </h4>
            </th>
            <th>-</th>

            <th class="">
              <h4>
                {{ totalSum.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
              </h4>
            </th>
            <th v-show="computedUserIsLeader">
              -
            </th>
          </tfoot>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog
      v-model="openModal"
      max-width="600px"
      persistent
    >
      <modal-inconsistencies
        :ids-sales="reports"
        :updated-table="updatedTable"
        @close="openModal = false"
      />
    </v-dialog>

    <v-dialog
      :key="itemId"
      v-model="openModalDelete"
      width="600px"
    >
      <v-card>
        <v-card-title class="d-flex justify-center mb-4">
          <span>Estorno de contrato</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <h4 class="ml-2 mb-2">
              Descrição:
            </h4>
            <v-textarea
              v-model="descriptionForDeleteContract"
              :rules="[rules.required]"
              label="Descreva o motivo para estornar o contrato"
              outlined
              dense
            ></v-textarea>
          </v-form>

          <div class="d-flex justify-end mt-4">
            <v-btn
              color="error"
              @click="openModalDelete = false"
            >
              Cancelar
            </v-btn>

            <v-btn
              color="info"
              class="ml-5"
              @click="deleteContract"
            >
              <span v-if="!loadingCircle">Enviar</span>
              <v-progress-circular
                v-else
                color="white"
                indeterminate
              >
              </v-progress-circular>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import storeMixin from '@/plugins/axiosMixins/storeMixin'
import myMixin from '@/plugins/mixin'
import messages from '@/plugins/showMessageMixin'
import {
  mdiAlertDecagram, mdiCheckDecagram, mdiClose, mdiDelete, mdiDownload,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import ModalInconsistencies from './ModalInconsistencies.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    ModalInconsistencies,
  },

  mixins: [myMixin, messages, storeMixin],

  props: {
    reference: { type: String, default: '' },
    vendorName: { type: String, default: '' },
    vendorCpf: { type: String, default: '' },
    statusSales: { type: String, required: true },
  },

  setup() {
    return {
      icons: {
        mdiClose,
        mdiDelete,
        mdiDownload,
        mdiCheckDecagram,
        mdiAlertDecagram,
      },
    }
  },

  data() {
    return {
      itemId: '',
      totalSum: [],
      salesListId: [],
      totalCredit: [],
      itemTableSelected: [],
      descriptionForDeleteContract: '',

      headers: [
        { text: 'CLIENTE', value: 'client_name' },
        { text: 'STATUS', value: 'contract_status', align: 'center' },
        { text: 'PROPOSTA', value: 'proposal_number', align: 'center' },
        { text: 'MODELO', value: 'vehicle_model', align: 'center' },
        { text: 'VALOR DA VENDA', value: 'sales', align: 'center' },
        { text: 'PERCENTUAL', value: 'percentage', align: 'center' },
        { text: 'VALOR COMISSÃO', value: 'commission', align: 'center' },
      ],

      rules: {
        required: value => !!value || 'Campo obrigatório.',
      },

      footer: [],
      reports: [],
      userProfile: [],

      openModal: false,
      loadingCircle: false,
      openModalDelete: false,
      loadingValidate: false,
      snackbarInconsistency: false,
    }
  },

  computed: {
    verifyUser() {
      return localStorage.getItem('userRole')
    },

    computedUserIsLeader() {
      if (this.userProfile.hierarchical_profile === 'nivel_2') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.headers.push({ text: 'ESTORNAR', value: 'delete', align: 'center' })

        return true
      }

      return false
    },
  },

  watch: {
    itemTableSelected(select) {
      const items = []
      select.map(item => {
        items.push(item.id)
      })

      this.salesListId = items
    },
  },

  created() {
    this.getList()
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
  },

  methods: {
    openInconsistenciesModal() {
      this.openModal = true
    },

    async validateSales() {
      const ids = []

      this.reports.map(item => {
        ids.push(item.id)
      })

      const body = {
        controlling_status: 'VALIDADO',
        contracts_id: ids,
      }

      axiosIns
        .post('api/v1/integration/contract/update_status', body)
        .then(res => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: `${res.data.data}`,
            timer: 4000,
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao validar vendas!',
            text: error,
          })
        })
    },

    async getList() {
      const body = {
        reference: this.reference,
        vendor_cpf: this.vendorCpf,
      }

      const totalList = []
      const totalCredit = []

      await axiosIns.post('api/v1/integration/contract/commission_detail', body).then(res => {
        this.reports = res.data.data

        res.data.data.map(item => {
          totalList.push(Number(item.commission))
          totalCredit.push(Number(item.sales))
        })
      })

      const sumTotal = totalList.reduce((sum, i) => sum + i)
      const sumCredit = totalCredit.reduce((sum, i) => sum + i)

      this.totalSum = sumTotal
      this.totalCredit = sumCredit
    },

    /**
     * @param {string} itemId
     */
    showModalDelete(itemId) {
      this.descriptionForDeleteContract = ''

      this.openModalDelete = true
      this.itemId = itemId
    },

    async deleteContract() {
      const formIsValid = this.$refs.form.validate()
      const endpoint = 'api/v1/integration/history_deleted_contract/store'

      const body = {
        text: this.descriptionForDeleteContract,
        contract: this.itemId,
      }

      if (formIsValid) {
        this.loadingCircle = true

        await this.postRegister(endpoint, body)

        this.loadingCircle = false

        return
      }

      this.showMessage({
        title: 'Campos vazios!',
        text: 'Verifique se todos os campos estão preenchidos',
      })
    },

    async exportCsv() {
      const file = await this.getCsv()
      if (file) {
        saveAs(file)
      }
    },

    async getCsv() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('sheet')

      worksheet.columns = [
        { header: 'CLIENTE', key: 'client_name' },
        { header: 'STATUS', key: 'contract_status' },
        { header: 'MODELO', key: 'vehicle_model' },
        { header: 'Nº PROPOSTA', key: 'proposal_number' },
        { header: 'DIGITO', key: 'proposal_digit' },
        { header: 'VALOR DA VENDA', key: 'sales' },
        { header: 'PERCENTUAL', key: 'percentage' },
        { header: 'VALOR DA COMISSÃO', key: 'commission' },
      ]

      this.reports.map(item => {
        worksheet.addRow([
          item.client_name,
          item.contract_status,
          item.vehicle_model,
          item.proposal_number,
          item.proposal_digit,
          Number(item.sales),
          Number(item.percentage),
          Number(item.commission),
        ])
      })

      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        blob = new File([data], 'dados_comissões_detalhado', {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      })

      this.loaderExport = false

      return blob
    },
  },
}
</script>

<style>
.footer {
  height: 50px;
}
.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  height: 40px;
}
.contracts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  height: 50px;
  width: 100%;
}
</style>
